<template>
    <footer class="footer">
        <img src="../assets/logo.png" alt="logo" class="logo" />
        <p>
            <span class="questions-text">Got Questions?</span>
            <span class="call-text">Please Call <a href="tel:+18173862303" class="phone-link">(817) 386-2303</a></span>
        </p>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
};
</script>

<style scoped>
.footer {
    margin-top: 4rem;
    text-align: center;
}

.logo {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin: 0 auto;
}

.footer p {
    font-family: 'QuickSand', sans-serif;
    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 0.625rem;
}

.phone-link {
    color: #318CE7;
    text-decoration: underline;
}

.questions-text,
.call-text {
    display: block;
}

@media (min-width: 768px) {

    .questions-text,
    .call-text {
        display: inline;
    }

    .logo {
        max-width: 200px;
    }
}

@media (min-width: 1200px) {
    .logo {
        max-width: 300px;
    }
}
</style>
