<template>
    <nav class="navbar">
        <div class="navbar-logo">
            <img src="@/assets/logo.png" alt="Logo" class="logo" />
        </div>
        <div class="hamburger" @click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <ul class="navbar-links" :class="{ 'active': isMenuActive }">
            <li><router-link to="/" @click="closeMenu">Track Order</router-link></li>
            <li><router-link to="/past-orders" @click="closeMenu">Past Orders</router-link></li>
            <li><a href="tel:+18173862303" class="phone-link" @click="closeMenu">Get In Touch</a></li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "NavBar",
    data() {
        return {
            isMenuActive: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuActive = !this.isMenuActive;
        },
        closeMenu() {
            this.isMenuActive = false;
        },
    },
    watch: {
        $route() {
            this.isMenuActive = false;
        }
    }
};
</script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0A446B;
    padding: 1rem 2rem;
    color: white;
    position: relative;
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
    margin-right: 1rem;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 2rem;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

.navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}

.navbar-links a:hover {
    text-decoration: underline;
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    height: 50px;
}

.hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: white;
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
    }

    .navbar-links {
        flex-direction: column;
        gap: 1rem;
        display: flex;
        position: absolute;
        top: 70px;
        right: 10px;
        background-color: #0A446B;
        padding: 1rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 1;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }

    .navbar-links.active {
        max-height: 300px;
        /* Adjust this value based on the content height */
        opacity: 1;
    }

    .navbar-links li {
        margin: 0.5rem 0;
    }

    .hamburger {
        display: flex;
    }
}
</style>
