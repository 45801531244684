<template>
    <div class="order-table">
        <div v-if="loading" class="loading-overlay">
            <img src="../assets/images/dog.gif" alt="Loading..." class="loading-gif" />
            <h3 style="font-family: 'QuickSand'; font-size:25px; font-weight:bold"> Loading..Please Wait</h3>
        </div>
        <div v-if="orders.length">
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Description</th>
                        <th>Date Created</th>
                        <th>Status</th>
                        <th>Estimated Delivery Date</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, index) in orders" :key="index">
                        <td>{{ (currentPage - 1) * count + index + 1 }}</td>
                        <td>{{ order.description }}</td>
                        <td>{{ formatDate(order.date) }}</td>
                        <td>{{ order.status + " " + getStatusEmoji(order.status) }}</td>
                        <td>{{ formatDate(order.estimated_delivery_date) }}</td>

                    </tr>
                </tbody>
            </table>
            <div class="pagination">
                <button @click="fetchOrders(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
                <span>Page {{ currentPage }}</span>
                <button @click="fetchOrders(currentPage + 1)" :disabled="!hasMore">Next</button>
            </div>
            <router-link to="/past-orders"><button>Find Another</button></router-link>
        </div>
        <div v-else-if="!loading">
            <p class="no-orders-found2">Oops..Not found :(</p>

            <img src="../assets/images/searching2.gif" alt="Loading..." class="notFound-gif" />
            <p class="no-orders-found">No orders found for the given Client ID. Please check if it's correct</p>
            <button @click="tryAgain" class="try-again-button">Try Again</button>
        </div>

    </div>
</template>

<script>
import apiService from '../services/ApiService.js';

export default {
    props: ['clientId'],
    data() {
        return {
            loading: false,
            orders: [],
            currentPage: 1,
            count: 10,
            hasMore: true,
        };
    },
    async created() {
        await this.fetchOrders(this.currentPage);
    },
    methods: {
        async fetchOrders(page) {
            if (page < 1) return;
            this.loading = true;
            try {
                const dateFrom = '2001-01-01'; // Modify as needed
                const dateTo = new Date().toISOString().split('T')[0]; // Current date
                const clientId = this.clientId;
                const response = await apiService.fetchOrderData(dateFrom, dateTo, clientId, this.count, page);
                if (response && response.jobs) {
                    this.orders = response.jobs;
                    this.currentPage = page;
                    this.hasMore = response.jobs.length === this.count;
                } else {
                    this.orders = [];
                    this.hasMore = false;
                }
            } catch (error) {
                console.error("Failed to fetch order data:", error);
                this.orders = [];
                this.hasMore = false;
            } finally {
                this.loading = false;
            }
        },
        getStatusEmoji(status) {
            switch (status) {
                case 'Pending':
                    return '🕒';
                case 'Completed':
                    return '✅';
                case 'WIP':
                    return '👨‍💻';
                case 'Delivered':
                    return '🚚';
                default:
                    return '🔍';
            }
        },

        tryAgain() {
            this.$router.push({ name: 'PastOrdersPage', });
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateString).toLocaleDateString(undefined, options);
        }
    },
};
</script>

<style scoped>
.order-table {
    text-align: center;
}

.loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.try-again-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.try-again-button:hover {
    background-color: #0056b3;
}

.loading-gif {
    width: 100px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

.pagination {
    margin: 20px 0;
}

.no-orders {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notFound-gif {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-top: 10px;
}



footer {
    margin-top: 20px;
    text-align: center;
}

.logo {
    width: 200px;
    /* Default width for smaller screens */
    height: auto;
    margin: 0 auto;
}

footer p {
    font-family: 'QuickSand';
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}


.no-orders-found {
    font-family: 'QuickSand';
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;

}

@media (min-width: 768px) {
    .logo {
        width: 200px;
        /* Larger width for medium screens */
    }
}

@media (min-width: 1200px) {
    .logo {
        width: 300px;
        /* Larger width for larger screens */
    }
}

.phone-link {
    color: blue;
    text-decoration: underline;
}

.no-orders-found2 {
    font-family: 'QuickSand';
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;

}

footer a {
    color: inherit;
    text-decoration: none;
}

/* Your existing styles */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.order-table {
    text-align: center;
}

.loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-gif {
    width: 100px;
}

.loading-gif {
    width: 100px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

.pagination {
    margin: 20px 0;
}

footer {
    margin-top: 20px;
    text-align: center;
}

.logo {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

footer p {
    font-family: 'QuickSand';
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.pagination button {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-family: "QuickSand";
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.pagination button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination span {
    font-family: "QuickSand";
    font-size: 16px;
}


.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: "QuickSand";
}

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

input {
    padding: 10px 20px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-family: "QuickSand";
    font-size: 16px;
    transition: border-color 0.3s;
    width: 100%;
    max-width: 300px;
}

input:focus {
    border-color: #007bff;
    outline: none;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-family: "QuickSand";
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
    max-width: 300px;
}

button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.responsive-img {
    max-width: 100%;
    height: auto;
    max-height: 300px;
    max-width: 600px;
}

.loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
}

.loading-gif {
    width: 150px;
    height: auto;
}

.loading-text {
    margin-top: 20px;
    font-family: 'QuickSand';
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.loading-gif {
    width: 100px;
    height: 100px;
}

.order-table {
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    font-family: "QuickSand";
}

th,
td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

@media (min-width: 600px) {
    .input-container {
        flex-direction: row;
    }

    input {
        margin-right: 10px;
        margin-bottom: 0;
        width: auto;
    }

    button {
        width: auto;
    }
}
</style>
