<template>
    <div class="order-status-page">

        <div v-if="orderStatus" class="loading-overlay">


            <h2>Order Status for #{{ jobId }}</h2>
            <div class="input-container">

                <router-link to="/"> <button @click="trackOrder">Track Another Order</button></router-link>
            </div>

        </div>
        <order-status :status="orderStatus" />
        <!-- <router-link to="/">Back to Home</router-link> -->
    </div>
</template>

<script>
import OrderStatus from "./OrderStatus.vue";
import ApiService from "../services/ApiService";

export default {
    components: { OrderStatus },
    data() {
        return {
            jobId: this.$route.params.jobId,
            orderStatus: null,
        };
    },
    async created() {
        const response = await ApiService.fetchOrderStatus(this.jobId);
        if (response && response.status) {
            this.orderStatus = response.status;
        } else {
            console.log('Failed to fetch order status');
        }
    },
};
</script>

<style scoped>
.order-status-page {
    text-align: center;
    margin-top: 50px;
}

router-link {
    margin-top: 20px;
    display: inline-block;
    text-decoration: none;
    color: #007bff;
}


input {
    padding: 10px 20px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 100%;
    max-width: 300px;
}

/* General styles for input and button */
input:focus {
    border-color: #007bff;
    outline: none;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
    max-width: 300px;
}

/* Flex container for responsive layout */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
    .container {
        flex-direction: row;
        justify-content: space-between;
    }

    input {
        width: calc(100% - 320px);
        margin-right: 20px;
    }

    button {
        width: auto;
    }
}

/* Ensuring proper layout on small screens */
input {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
}
</style>