<template>
  <div class="order-status">
    <div v-if="status" class="timeline">
      <div class="timeline-step" :class="{ active: isActive('Pending') }">
        <img :src="getImage('Pending')" alt="Pending" />
        <div class="status-container">
          <div :class="{ 'current-status': status === 'Pending' }">
            Pending
          </div>
          <div v-if="status === 'Pending'" class="subheading">
            The order is ready, but has not been submitted for production yet.
          </div>
        </div>
      </div>
      <div class="timeline-line" :class="{ 'line-active': isLineActive('Pending') }"></div>
      <div class="timeline-step" :class="{ active: isActive('Submitted') }">
        <img :src="getImage('Submitted')" alt="Submitted" />
        <div class="status-container">
          <div :class="{ 'current-status': status === 'Submitted' }">
            Submitted
          </div>
          <div v-if="status === 'Submitted'" class="subheading">
            The order has been submitted and is in the process of being scheduled for production.
          </div>
        </div>
      </div>
      <div class="timeline-line" :class="{ 'line-active': isLineActive('Submitted') }"></div>
      <div class="timeline-step" :class="{ active: isActive('WIP') }">
        <img :src="getImage('WIP')" alt="Work in Progress" />
        <div class="status-container">
          <div :class="{ 'current-status': status === 'WIP' }">
            In Progress
          </div>
          <div v-if="status === 'WIP'" class="subheading">
            The order is currently in production and final shipping information should be available soon.
          </div>
        </div>
      </div>
      <div class="timeline-line" :class="{ 'line-active': isLineActive('WIP') }"></div>
      <div class="timeline-step" :class="{ active: isActive('Delivered') }">
        <img :src="getImage('Delivered')" alt="Delivered" />
        <div class="status-container">
          <div :class="{ 'current-status': status === 'Delivered' }">
            Delivered
          </div>
          <div v-if="status === 'Delivered'" class="subheading">
            The order has been shipped and you should have tracking information or the order should be delivered.
          </div>
        </div>
      </div>
      <div class="timeline-line" :class="{ 'line-active': isLineActive('Delivered') }"></div>
      <div class="timeline-step" :class="{ active: isActive('Completed') }">
        <img :src="getImage('Completed')" alt="Completed" />
        <div class="status-container">
          <div :class="{ 'current-status': status === 'Completed' }">
            Completed
          </div>
          <div v-if="status === 'Completed'" class="subheading">
            The order has been delivered and the invoice has been paid.
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!loading">
      <p class="no-orders-found2">Oops..Not found :(</p>
      <img src="../assets/images/searching2.gif" alt="Loading..." class="notFound-gif" />
      <p class="no-orders-found">No orders found for the given ID. Please check if it's correct</p>
      <router-link to="/"> <button class="try-again-button">Try Again</button></router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    orderId: String,
    loading: Boolean
  },
  methods: {
    getImage(step) {
      const gifImages = {
        Pending: require("../assets/images/Pending.gif"),
        Submitted: require("../assets/images/Submitted.gif"),
        WIP: require("../assets/images/WIP.gif"),
        Delivered: require("../assets/images/Delivered.gif"),
        Completed: require("../assets/images/done.gif"),
      };
      const staticImages = {
        Pending: require("../assets/images/pending.png"),
        Submitted: require("../assets/images/submitted.png"),
        WIP: require("../assets/images/wip.png"),
        Delivered: require("../assets/images/delivered.png"),
        Completed: require("../assets/images/done.png"),
      };
      return this.status === step ? gifImages[step] : staticImages[step];
    },
    isActive(step) {
      const statusOrder = ['Pending', 'Submitted', 'WIP', 'Delivered', 'Completed'];
      return statusOrder.indexOf(this.status) >= statusOrder.indexOf(step);
    },
    isLineActive(step) {
      const statusOrder = ['Pending', 'Submitted', 'WIP', 'Delivered', 'Completed'];
      return statusOrder.indexOf(this.status) > statusOrder.indexOf(step);
    },
    tryAgain() {
      this.$emit('try-again');
    }
  },
};
</script>

<style scoped>
.order-status {
  text-align: center;
  margin-top: 50px;
}

.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.timeline-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  opacity: 0.5;
  position: relative;
  z-index: 1;
}

.timeline-step.active {
  opacity: 1;
}

.timeline-step img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.timeline-step .status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-step div.current-status {
  font-size: 1em;
  font-weight: bold;
  font-family: "QuickSand";
}

.timeline-line {
  height: 4px;
  background-color: #ddd;
  flex: 1;
  position: relative;
  z-index: 0;
}

.timeline-line.line-active {
  background-color: #4caf50;
}

.no-orders-found2 {
  font-family: "QuickSand";
  font-weight: bold;
  font-size: 25px;
  margin-top: 10px;
}

.notFound-gif {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-top: 10px;
}

.no-orders-found {
  font-family: "QuickSand";
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
}

.try-again-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.try-again-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.track-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.track-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .timeline {
    flex-direction: column;
    align-items: center;
  }

  .timeline-step {
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .timeline-step img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .timeline-step .status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .timeline-step div.current-status {
    font-size: 0.8em;
  }

  .subheading {
    font-size: 0.8em;
    margin-top: 5px;
    width: 80%;
    text-align: left;
  }

  .timeline-line {
    width: 4px;
    height: 100px;
    background-color: #ddd;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .timeline:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: #ddd;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;
  }

  .timeline-step.active:before {
    content: '';
    position: absolute;
    width: 4px;
    height: calc(100% + 20px);
    background-color: #4caf50;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;
  }
}
</style>
