<template>
    <div>
        <NavBar />
        <router-view />
        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import Footer from "./FooterComponent.vue";


export default {
    components: {
        NavBar,
        Footer
    },
};
</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
}
</style>