import axios from "axios";

const BASE_URL = "https://us-central1-hfcustomsol.cloudfunctions.net/";

export default {
  async fetchOrderStatus(jobId) {
    try {
      const response = await axios.get(`${BASE_URL}fetchOrderStatus`, {
        params: { id: jobId },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch order status:", error);
      return null;
    }
  },

  async fetchSalesOrder(jobId) {
    try {
      const response = await axios.get(`${BASE_URL}fetchSalesOrder`, {
        params: { id: jobId },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch sales order status:", error);
      return null;
    }
  },

  async fetchOrderData(dateFrom, dateTo, clientId, count, page) {
    try {
      console.log("fetchOrderData parameters:", {
        dateFrom,
        dateTo,
        clientId,
        count,
        page,
      }); // Log the parameters
      const response = await axios.get(`${BASE_URL}fetchOrderData`, {
        params: { dateFrom, dateTo, clientId, count, page },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch order data:", error);
      return null;
    }
  },
};
