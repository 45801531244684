<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@font-face {
  font-family: "QuickSand";
  src: url('~@/assets/fonts/Quicksand-VariableFont_wght.ttf');
}


@font-face {
  font-family: "GB";
  src: url('~@/assets/fonts/gb.otf');
}

@font-face {
  font-family: "GR";
  src: url('~@/assets/fonts/gr.otf');
}



body {
  margin: 0;
  padding: 0;
}
</style>
