// import { createRouter, createWebHistory } from "vue-router";
// import Layout from "../components/LayOut.vue";
// import OrderStatusPage from "../components/OrderStatusPage.vue";

// const routes = [
//   {
//     path: "/",
//     component: Layout,
//     children: [
//       {
//         path: "",
//         name: "OrderInput",
//         component: () => import("../components/OrderInput.vue"), // Lazy loading OrderInput
//       },
//       {
//         path: "order-status/:jobId",
//         name: "OrderStatusPage",
//         component: OrderStatusPage,
//         props: true,
//       },
//     ],
//   },
// ];

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });

// export default router;

import { createRouter, createWebHistory } from "vue-router";
import Layout from "../components/LayOut.vue";
import OrderStatusPage from "../components/OrderStatusPage.vue";
import PastOrdersPage from "../components/PastOrders.vue";
import OrderTable from "../components/OrderTable.vue";

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "OrderInput",
        component: () => import("../components/OrderInput.vue"), // Lazy loading OrderInput
      },
      {
        path: "order-status/:jobId",
        name: "OrderStatusPage",
        component: OrderStatusPage,
        props: true,
      },
      {
        path: "order-table/:clientId",
        name: "OrderTable",
        component: OrderTable,
        props: true,
      },
      {
        path: "past-orders",
        name: "PastOrdersPage",
        component: PastOrdersPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

// // export default router;
// import { createRouter, createWebHistory } from "vue-router";
// import Layout from "../components/LayOut.vue";
// import OrderStatusPage from "../components/OrderTable.vue";
// import PastOrdersPage from "../components/PastOrders.vue";

// const routes = [
//   {
//     path: "/",
//     component: Layout,
//     children: [
//     //   {
//     //     path: "order-status/:jobId",
//     //     name: "OrderStatusPage",
//     //     component: OrderStatusPage,
//     //     props: true,
//     //   },
//       {
//         path: "",
//         name: "OrderInput",
//         component: () => import("../components/OrderInput.vue"), // Lazy loading OrderInput
//       },
//       {
//         path: "order-table/:jobId",
//         name: "OrderTable",
//         component: OrderStatusPage,
//         props: true,
//       },
//       {
//         path: "past-orders",
//         name: "PastOrdersPage",
//         component: PastOrdersPage,
//       },
//     ],
//   },
// ];

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });

// export default router;
