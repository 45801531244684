<template>
    <div class="order-input">
        <h2 style="font-family: 'QuickSand';">Find Past Orders</h2>
        <img src="../assets/images/searching.gif" alt="DeliveryImage" class="responsive-img" />
        <p style="font-family: 'QuickSand'; font-size:20px">Look up past orders!</p>
        <div class="input-container">
            <input v-model="clientId" placeholder="Enter Client ID" @keyup.enter="trackOrder" />
            <button @click="trackOrder">Find All Orders</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clientId: "",
        };
    },
    methods: {
        trackOrder() {
            if (this.clientId) {
                console.log(this.clientId);
                this.$router.push({ name: 'OrderTable', params: { clientId: this.clientId } });
            } else {
                console.log('Please enter a valid client ID');
            }
        }
    },
};
</script>

<style scoped>
.order-input {
    text-align: center;
    margin-top: 50px;
}

body {
    margin: 0;
    padding: 0;
    font-family: "QuickSand";
}

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

input {
    padding: 8px 16px;
    /* Adjusted padding */
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-family: "QuickSand";
    font-size: 14px;
    /* Adjusted font size */
    transition: border-color 0.3s;
    width: 100%;
    max-width: 250px;
    /* Adjusted max width */
}

input:focus {
    border-color: #007bff;
    outline: none;
}

button {
    padding: 8px 16px;
    /* Adjusted padding */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    /* Adjusted font size */
    font-family: "QuickSand";
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
    max-width: 250px;
    /* Adjusted max width */
}

button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.responsive-img {
    max-width: 100%;
    height: auto;
    max-height: 200px;
    max-width: 500px;
}

.loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    /* Adjust as needed for overlay effect */
}

.loading-gif {
    width: 150px;
    /* Adjust size as needed */
    height: auto;
}

.loading-text {
    margin-top: 20px;
    /* Space between the image and text */
    font-family: 'QuickSand';
    /* Change font to your preference */
    font-size: 1.5rem;
    /* Adjust font size as needed */
    color: #333;
    font-weight: bold;
    /* Adjust color as needed */
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.loading-gif {
    width: 100px;
    /* Adjust size as needed */
    height: 100px;
    /* Adjust size as needed */
}

.order-table {
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    font-family: "QuickSand";
}

th,
td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

@media (min-width: 600px) {
    .input-container {
        flex-direction: row;
    }

    input {
        margin-right: 10px;
        margin-bottom: 0;
        width: auto;
    }

    button {
        width: auto;
    }
}
</style>
